@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@layer components {
  .btn {
    @apply rounded-full px-4 py-1.5 text-sm border border-violet-900 bg-white text-violet-900 hover:bg-violet-900 hover:text-white;
  }
}